.indexpage {
	.single-unit {
		.unit-cont-bl {
			.unit-price-rat {
				a.unit-link {
					position: static;
					width: 100%;
					text-align: right;
				}
			}
		}
	}

	.gaste-feedback {
		padding-top: 100px;

		.section-title {
			margin: 0 auto;
			text-align: center;
		}

	}

	.parallax-section {
		background-image: url(RESOURCE/img/bg-home-parallax2.jpg);
		position: relative;
		background-repeat: no-repeat;
		background-position: center;
		background-size: cover;
		background-attachment: fixed;
		margin-top: 20px;

		@media (max-width:640px) {
			margin-top: 0px;
		}

		&:before {
			background-color: var(--white);
			height: 130px;
			width: 100%;
			display: block;
			position: absolute;
			left: 0;
			top: -1px;
			content: "";

			@media (max-width:992px) {
				height: 80px;
			}
		}

		&:after {
			background-color: var(--white);
			height: 130px;
			width: 100%;
			display: block;
			position: absolute;
			left: 0;
			bottom: -1px;
			content: "";

			@media (max-width:992px) {
				height: 80px;
			}
		}


		.parallax-content {
			padding: 80px;
			position: relative;
			z-index: 1;
			background-color: var(--secondary);
			color: var(--white);
			background-repeat: no-repeat;
			background-position: center;
			background-size: cover;
			max-width: 800px;


			.section-title {
				margin-bottom: 40px;

				>h1 {
					font-family: var(--cursive-heading);
					font-size: 80px;

					@media (max-width:640px) {
						font-size: 60px;
					}
				}

				&:after {
					opacity: .2;
					background-color: var(--white);
				}

				@media (max-width:992px) {
					margin-bottom: 20px;
				}
			}

			@media (max-width:992px) {
				min-height: 510px;
				padding: 20px;
			}

			@media (max-width:768px) {
				min-height: auto;
			}

			p {
				font-size: 24px;
				color: var(--white);
				margin-bottom: 40px;

				@media (max-width:992px) {
					font-size: 14px;
					margin-bottom: 20px;
				}
			}

			a {
				color: var(--text-dark);

				&:hover {
					color: var(--secondary);
				}
			}
		}
	}

	.bg-li-blue {
		@media (max-width:992px) {
			background-color: var(--white);
		}

		.section-title.align-left {
			margin-bottom: 0;

			&:after {
				@media (min-width:993px) {
					opacity: .2;
				}
			}
		}

		.testimonial-slide {
			padding: 50px 0;
			/*background-image: url(https://r.v-office.com/preview/v115/1601984139000/img/bg-testimonial-circle.png);*/
			background-repeat: no-repeat;
			background-position: center;
			background-size: 430px;

			@media (max-width:992px) {
				padding: 30px 0;
			}

			@media (max-width:450px) {
				background-position: center center;
				background-size: 300px;
			}

			.cl-img {
				margin: 0 40px 40px;
				position: relative;

				@media (max-width:992px) {
					margin: 0 20px 30px;
				}

				img {
					position: relative;
					z-index: 1;

					@media (max-width:992px) {
						left: -8px;
						top: -8px;
					}
				}

				&:after {
					border: 3px solid #aecad7;
					position: absolute;
					left: 30px;
					height: 100%;
					top: 30px;
					width: 100%;
					content: "";

					@media (max-width:992px) {
						left: 15px;
						top: 15px;
					}
				}
			}

			.owl-nav {
				display: none;
			}

			.cl-dt {
				text-align: center;
				padding-top: 4px;
				display: block;

				h3 {
					color: var(--secondary);
					font-family: var(--d-font2);
				}

				h5 {
					a {
						text-transform: uppercase;
						color: rgba(0, 0, 0, .4);
						font-family: var(--d-font2);
					}
				}
			}

			.cl-rat {
				color: var(--primary);
				font-size: 18px;

				@media (max-width:992px) {
					text-align: center;
				}
			}

			.cl-cont-bl {
				padding: 20px;


				@media (min-width:993px) {
					padding: 0 0 0 30px;
				}

				@media (max-width:992px) {
					margin-top: 0;
				}

				.testi-title {
					color: var(--text-dark);
					margin-bottom: 50px;

					@media (max-width:992px) {
						text-align: center;
					}
				}

				p {
					font-size: 30px;
					margin-bottom: 30px;
					line-height: 1.4;
					color: var(--text-light);

					@media (max-width:992px) {
						font-size: 21px;
					}
				}
			}

			.owl-dots {
				bottom: 0px;
				width: 50%;
				left: 50%;
				transform: translate(-50%, 0);
				font-size: 27px;
				text-align: center;
				position: absolute;

				.owl-dot {
					width: 50px;
					height: 0;
					display: inline-block;
					border-bottom: 5px solid var(--secondary);
					margin: 0 8px;
					opacity: .2;

					&.active {
						border-bottom-color: var(--primary);
						opacity: 1;
					}

					@media (max-width:992px) {
						width: 40px;
					}
				}
			}
		}
	}

	.blog-block {
		border: 1px solid #dddddd;
		box-shadow: 0 0 10px rgba(0, 0, 0, .1);
		border-radius: 10px;
		overflow: hidden;

		@media (min-width:993px) {
			margin-bottom: 40px;
		}

		.bl-img {
			height: 250px;
			overflow: hidden;
			display: block;

			img {
				object-fit: cover;
				width: 100%;
				height: 100%;
			}
		}

		.date {
			color: var(--grey);
		}

		.lt-bl-cont {
			padding: 15px 20px;

			.bl-title {
				margin-top: 0;
				color: var(--black);
				font-size: 18px;
				text-align: left;

				a {
					color: inherit;
				}
			}
		}

	}


	.flex-blog-col {
		@media (min-width:993px) {
			display: flex;
			align-items: center;
			flex-direction: row-reverse;
			flex-wrap: wrap;
		}

		@media (max-width:992px) {
			text-align: center;
		}


		.blog-col {
			@media (max-width:992px) {
				margin-top: 40px !important;
			}

			&:nth-child(1),
			&:nth-child(3) {
				position: relative;

				@media (min-width:993px) {
					top: -25px;
				}
			}

			&:nth-child(2),
			&:nth-child(4) {
				position: relative;

				@media (min-width:993px) {
					top: 25px;
				}
			}

			@media (max-width:992px) {
				max-width: 440px;
				margin: 0 auto;
				padding: 0 15px;
			}

		}
	}

	.category-bl {
		margin: 0;

		.col-lg-6 {
			padding: 0;
			position: relative;


			.row {
				margin: 0;

				.col-sm-6 {
					padding: 0;
					position: relative;
				}
			}
		}

		.big-cat-bl {
			border: 1px solid #e8e8e8;
			width: 100%;
			overflow: hidden;

			@media (min-width:993px) {
				height: 791px;

				&:after {
					content: "";
					height: 100%;
					width: 100%;
					background: rgb(8, 155, 224);
					background: linear-gradient(360deg, rgba(176, 50, 58, .80), rgba(51, 51, 51, .80) 75%);
					position: absolute;
					z-index: 1;
					top: 0;
					left: 0;
					visibility: hidden;
					opacity: 0;
					padding: 30px;
					transition: all 0.25s ease 0s;
				}
			}

			.cat-main-title {
				visibility: visible;
				opacity: 1;
				transition: all 0.25s ease 0s;
			}


			@media (min-width:993px) {
				&:hover {
					&:after {
						visibility: visible;
						opacity: 1;
					}

					.cat-cont-bl {
						visibility: visible;
						opacity: 1;
					}

					.cat-main-title {
						visibility: hidden;
						opacity: 0;
					}
				}


			}


			>img {

				@media (min-width:993px) {
					object-fit: cover;
					height: 100%;
					width: 100%;
				}
			}

			.cat-titile {
				@media (min-width:993px) {
					color: var(--white);
					font-size: 30px;
				}

				font-size: 20px;
				margin: 0 0 20px;


			}

			p {
				font-size: 24px;

				@media (max-width:992px) {
					font-size: 18px;
					margin-bottom: 30px;
				}
			}
		}



		.half-cat-bl {
			border: 1px solid #e8e8e8;
			width: 100%;
			overflow: hidden;
			position: relative;

			@media (min-width:993px) {
				height: 396px;

				&:after {
					content: "";
					height: 100%;
					width: 100%;
					background: rgb(8, 155, 224);
					background: linear-gradient(360deg, rgba(255, 255, 255, .50), rgba(196, 191, 182, .95) 75%);
					position: absolute;
					z-index: 1;
					top: 0;
					left: 0;
					visibility: hidden;
					opacity: 0;
					padding: 30px;
					transition: all 0.25s ease 0s;
				}
			}

			.cat-main-title {
				visibility: visible;
				opacity: 1;
				transition: all 0.25s ease 0s;
			}


			.cat-titile {
				@media (min-width:993px) {
					color: var(--white);
					font-size: 30px;
				}

				margin: 0 0 20px;
			}


			@media (min-width:993px) {
				&:hover {
					&:after {
						visibility: visible;
						opacity: 1;
					}

					.cat-cont-bl {
						visibility: visible;
						opacity: 1;
					}

					.cat-main-title {
						visibility: hidden;
						opacity: 0;
					}

					.big-cat-gr {
						&:after {
							display: none;
						}
					}
				}
			}

			>img {
				@media (min-width:993px) {
					object-fit: cover;
					height: 100%;
					width: 100%;
				}

				width: 100%;
			}

			h4 {
				@media (min-width:993px) {
					bottom: 18px;
					right: 24px;
					position: absolute;
					text-align: right;
					text-shadow: 0 0px 7px rgba(0, 0, 0, .7);
					font-size: 28px;
				}

				@media (max-width:992px) {
					font-size: 24px;
				}
			}
		}

		p {
			font-size: 20px;
			margin-bottom: 30px;
			opacity: .75;
			margin-bottom: 40px;
			max-width: 550px;

			@media (min-width:993px) {
				color: var(--white);
			}

			@media (max-width:992px) {
				font-size: 18px;
				margin-bottom: 30px;

			}
		}

		.cat-cont-bl {
			@media (min-width:993px) {
				visibility: hidden;
				opacity: 0;
				transition: all 0.25s ease 0s;
			}

			@media (max-width:992px) {
				padding: 20px;
			}
		}


		.big-cat-gr {
			position: relative;

			@media (min-width:993px) {
				position: absolute;
				z-index: 1;
				top: 0;
				left: 0;
				width: 100%;
				height: 100%;
				z-index: 2;
				padding: 40px;
			}

			.link {
				font-size: 18px;
				text-transform: capitalize;

				@media (max-width:992px) {
					color: var(--primary);
				}

				&:hover {
					color: var(--white);

					@media (max-width:992px) {
						color: var(--secondary);
					}
				}
			}

			.cat-main-title {
				text-shadow: 0 0px 7px rgba(0, 0, 0, .7);
				z-index: 3;

				@media (min-width:993px) {
					font-size: 28px;
					position: absolute;
					bottom: 20px;
					right: 40px;

				}

				a {
					color: var(--white);

					&:hover {
						color: var(--primary);
					}
				}


				@media (max-width:992px) {
					font-size: 24px;
					position: absolute;
					z-index: 5;
					top: -60px;
					right: 17px;
					display: none;
				}
			}

			&:after {
				height: 140px;
				bottom: 0;
				left: 0;
				right: 0;
				position: absolute;
				display: block;
				background: -moz-linear-gradient(top, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 0) 20%, rgba(0, 0, 0, 0.75) 50%, rgba(0, 0, 0, 0.75) 100%);
				background: -webkit-linear-gradient(top, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 0) 20%, rgba(0, 0, 0, 0.75) 50%, rgba(0, 0, 0, 0.75) 100%);
				background: linear-gradient(to bottom, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 0) 20%, rgba(0, 0, 0, 0.75) 50%, rgba(0, 0, 0, 0.75) 100%);
				filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#00000000', endColorstr='#cc000000', GradientType=0);
				content: "";
				opacity: 0.6;

				@media (max-width:992px) {
					display: none;
				}

			}
		}
	}
}