:root {
	--introImg: url(RESOURCE/img/banner.jpg);
	--mobileMenuImg: url(RESOURCE/img/banner01.Xc_900x600.jpg);
	--navbarbreak: 768px;
	/*--primary: #a99e8c;
	--secondary: #867C6B;*/

	/*--primary: #867c6b;*/
	--primary: #c4bfb6;
	--primary-dark: #b3aea4;
	--secondary: #5f5f5f;

	/*--primary: #cbbba0;
	--secondary: #b0323a;
	--secondary: #6d1212;
	--light: #e6e3df;*/
	--light: #eaeaea;
	--light-x: #f6f3ed;
	/*--lighter: #E0D8CD;*/
	/*--lighter: #e8e7e6;*/
	--lighter: #e5e5e5;
	/*--darker: #cdc2b2;*/
	/*--darker: #d3d1cf;*/
	--darker: #c4bfb6;
	--text-dark: #000000;
	--text-light: #999999;
	--lightx: #dbd9d6;
	/*--li-blue: #f7f7f5;*/
	--li-blue: #f9f9f9;
	--dark-grey: #5f5f5f;

	/*--red: #ff0000;*/
	--black: #000000;
	--d-font: 'DINRoundPro', sans-serif;
	--d-font2: 'DINRoundPro-Medi', sans-serif;
	--cursive-heading: 'Mr De Haviland', cursive;
	--white: #ffffff;
	--d-blue: #033848;
	--d-blue2: #03364d;
	--h1-font: 'Julius Sans One', sans-serif;
	--grey: #e5e5e5;
	--d-grey: #727272;
	--blue3: #025a84;
	--f-nunito: 'Nunito', sans-serif;
	--blue4: #052444;
	/*--li-blue: #fbfaf9;*/
	--grey1: #cccccc;
	--grey2: #7b7b7b;
	--red: #f55454;
	--grey3: #999999;
	--d-font3: 'Ubuntu', sans-serif;
	--grey4: #f9f9f9;

	/*Calendar colors*/
	--not_available: #cd7898;
	--disabled: rgba(86, 90, 92, .5);
	--available: #fff;
	--selected: #062e5f;
	--selection_range: #4D6E96;
	--selected_border: #fff;
	--text_color: #000;
}