.static {
	display: block;
}


.inner-banner {
	background-image: url(RESOURCE/img/start1.jpg);
	background-position: center center;
	background-repeat: no-repeat;
	background-size: cover;
	height: auto !important;
	position: relative;
	padding: 15rem 0;

	&.timmendorfer {
		background-image: url(RESOURCE/img/start3.jpg);
		background-position: center 55%;
	}

	&.ostsee-theme {
		background-image: url(RESOURCE/img/ostsee-therme-bg.jpg);
	}

	&.hansa-park {
		background-image: url(RESOURCE/img/hansa-park-bg.jpg);
	}

	&.karls-erdbeerhof {
		background-image: url(RESOURCE/img/karls-erdbeerhof-bg.jpg);
	}

	&.sea-life-center {
		background-image: url(RESOURCE/img/sea-life-center-bg.jpg);
	}

	&.strandleben-beach {
		background-image: url(RESOURCE/img/timmendorfer-strand-bg.jpg);
		background-position: center 55%;
	}

	&.golf {
		background-image: url(RESOURCE/img/golf-bg.jpg);
	}

	&.reiseversicherung {
		background-image: url(RESOURCE/img/reiseversicherung-bg.jpg);
	}

	&.impressum {
		background-image: url(RESOURCE/img/impressum-bg.jpg);
	}


	&.faq {
		background-image: url(RESOURCE/img/reiseversicherung-bg.jpg);
	}

	&.blog {
		background-image: url(RESOURCE/img/banner06.jpg);
	}

	&.contact {
		background-image: url(RESOURCE/img/start3.jpg);
		background-position: 100% 60%;
	}

	&.aboutus {
		background-image: url(RESOURCE/img/aboutus-bg.jpg);
		background-position: center 45%;
	}

	&.gutscheine {
		background-image: url(RESOURCE/img/gutscheine-bg.jpg);
		background-position: center 55%;
	}

	&.vermietservice {
		background-image: url(RESOURCE/img/panorama1.jpg);
		background-position: center;
	}

	&.neuigkeiten {
		background-image: url(RESOURCE/img/neuigkeiten-bg.jpg);
		background-position: center 40%;
	}

	&.facility-bg {
		background-image: url(RESOURCE/img/facility-bg.jpg);
		background-position: center 55%;
	}

	&.facilities {
		background-image: url(RESOURCE/img/vermietservice-bg.jpg);
		background-position: center;
	}

	&.hund {
		background-image: url(RESOURCE/img/dog-friendly.jpg);
		background-position: center 65%;
	}



	@media (max-width:992px) {
		height: 320px;
	}


	.page-title {
		position: absolute;
		bottom: 70px;
		width: 100%;
		left: 0;
		z-index: 2;
		margin: 0;
		border-bottom: none;

		@media (max-width:992px) {
			bottom: 30px;
		}


		h1 {
			font-size: 42px;
			margin-bottom: 0;
			color: var(--white);
			text-transform: uppercase;

			@media (max-width:992px) {
				font-size: 30px;
			}
		}

	}
}

.heading1 {
	font-size: 42px;
}


.teams-bl {
	max-width: 1040px;
	margin: 0 auto;
	display: flex;
	flex-wrap: wrap;
	justify-content: center;

	.team-bl {
		border: 1px solid #e8e8e8;

		.team-dt {

			border-top: none;
			padding: 20px 8px;
			text-align: center;
		}
	}
}


.contact-page {
	.control-label {
		text-align: left;
		padding-bottom: 5px;
	}

	.form-control {
		border: none;
		border-bottom: 2px solid #f2f2f2;
		text-transform: uppercase;
		margin-bottom: 30px;
		border-radius: 0;
		box-shadow: inherit;
		padding: 0;
	}

	label {
		color: var(--black);
		font-weight: 700;
		font-size: 14px;
		text-transform: uppercase;
		margin-bottom: 0;
		padding-bottom: 10px;
	}

	.checkbox {
		label {
			text-transform: inherit;
			font-weight: normal;
		}

		a {
			color: var(--black);
			font-size: 14px;
		}

		.privacy-link {
			position: relative;
		}

	}

	.btn-go {
		box-shadow: 2px 1px 10px rgba(0, 0, 0, .2);
		font-size: 20px;
	}
}

.inner-content-wrap {

	padding-top: 98px;
	margin-bottom: 50px;

	@media (max-width:992px) {
		/*margin-top: 130px;*/
		padding-top: 60px;
	}

	ul.two-col {
		margin: 0 0 0 40px;
		padding: 0px;

		li {
			line-height: 26px;

			>span {
				width: 40%;
				display: inline-block;

				&+span {
					margin-left: 50px;
				}
			}
		}
	}

	>.static-view {
		margin-top: 0px;
		margin-bottom: 80px;
	}

	h3.subtitle {
		font-size: 22px;
		font-weight: 400;
	}
}

.blogcontent {
	h2 {
		@media (max-width: 480px) {
			word-break: break-word;
		}
	}
}

.contact-content {
	iframe {
		@media (max-width: 991px) {
			height: 350px !important;
		}
	}
}

.ct-form-section {
	.mapnote {
		margin: 0 0 15px 0;
		font-size: 14px;

		a {
			color: var(--primary);

			&:hover {
				color: var(--secondary);
			}
		}
	}

	.mapconsent {
		background: rgba(26, 121, 187, .15);
		padding: 15px;

		a {
			background: var(--primary);
			color: #fff;
			border-radius: 3px;
			padding: 0 15px;
			height: 36px;
			display: inline-block;
			line-height: 35px;
			margin: 0 0 15px 0;

			&:hover {
				background: var(--secondary);
			}
		}
	}
}