@import "setup.css";

.mainHeader {
	&.affix-top {
		.sr-close {
			visibility: hidden;
		}
	}
}

.header-style2 {
	.vo-nav-bar {
		.top {
			background-color: var(--darker) !important;
		}
	}
}

.header-style2 {
	.mainHeader {
		/*padding: 14px 0 !important;*/
		border-bottom: 0px solid #f9f9f9;
		/*background-color: rgba(255, 255, 255, 1) !important;*/
		background-color: var(--darker) !important;
		padding: 0px !important;

		.navbar-area {

			.topNav {
				ul {
					li {
						a {
							text-decoration: inherit;
							text-shadow: none;
							/*color: var(--black);*/
							color: var(--white);
						}
					}
				}
			}


		}
	}



}

.navbar-area {

	>.srch-btn {
		color: var(--primary) !important;



		i.fal,
		i.far {
			@media (max-width:992px) {
				display: none;
			}
		}
	}

	.sr-close {
		position: absolute;
		z-index: 2;
		right: 0;

		i {
			color: #333;
			color: var(--text-dark);
			font-size: 24px;
			padding: 0 10px;
		}

		@media (max-width:1024px) {
			display: none !important;
		}
	}

}

.nav-right {
	.srch-btn {
		padding: 2px 8px;
		height: 34px;
		margin: 0 !important;

		&:hover {
			/*background-color: rgba(0, 0, 0, 0.15) !important;*/
		}
	}
}

.vo-nav-bar {

	.top {
		/* background-color: var(--secondary); */
		padding: 10px 0;
		/*background-color: var(--secondary);*/
		/* background: linear-gradient(180deg, #b0323ae3 0, rgb(210 79 87 / 49%) 50%, rgb(210 79 87 / 26%)); */

		ul {
			margin: 0px;
			padding: 0px;
			text-align: right;

			li {
				list-style-type: none;
				display: inline-block;
				margin-left: 10px;
				vertical-align: middle;

				a {
					text-decoration: none;
					color: var(--white);
					font-size: 15px;

					i {
						font-size: 20px;
						vertical-align: middle;
					}
				}

				.fav-count {
					color: var(--white);
					position: relative;


					.fa {
						font-size: 24px;
						margin-right: 5px;
						vertical-align: middle;
					}

					.sub-count {
						display: block;
						position: absolute;
						top: -5px;
						left: 10px;
						width: 18px;
						height: 18px;
						background: var(--secondary);
						/*background: var(--black);*/
						color: var(--white);
						text-align: center;
						font-size: 12px;
						line-height: 18px;
						border-radius: 50%;
					}

					span {

						display: none;

						@media (max-width:992px) {}
					}
				}
			}

		}

		@media (max-width:992px) {
			display: none;

		}

	}

	&.mainHeader {
		position: fixed;
		/*position: absolute;*/
		width: 100%;
		top: 0px;
		z-index: 50;
		/*display: flex;*/
		align-items: center;
		padding: 0;
		/*padding: 28px 0;*/
		transition: all ease-in .2s;
		background-color: rgba(255, 255, 255, 0);

		@media (max-width:992px) {
			/*padding: 14px 0 !important;*/
			border-bottom: 0px solid #f9f9f9;
			/*background-color: #fff !important;*/
			background-color: var(--darker) !important;
		}

		.navbar-area {
			display: flex;
			justify-content: space-between;
			align-items: center;
			padding: 10px 0;
			transition: all .2s ease-in;
			position: relative;

			@media (max-width:992px) {
				/*padding: 0 0 0 50px;*/
				position: relative;
				text-align: center;
				padding: 0px !important;
				/*width: 100%;
				display: inline-block;*/
			}

			.srch-btn {
				font-size: 20px;
				color: var(--white);

				@media (max-width:992px) {
					margin-right: 5px;
					color: var(--primary);
				}
			}

			.logo {

				img {
					transition: all .2s ease-in;
					/*background-color: var(--white);
					padding: 5px 10px;
					border-radius: 8px;*/
					display: inline-block;
					width: 180px;
					position: absolute;
					top: -32px;
					left: 40px;

					@media (max-width:1024px) {
						width: 140px;
					}

					@media (max-width:992px) {
						&:first-child {
							display: none;
						}
					}


					&.scroll {
						opacity: 0;
						visibility: hidden;
						transition: all .2s ease-in;

						@media (max-width:992px) {
							opacity: 1;
							visibility: visible;
							width: 60px !important;
							transition: none;
							position: relative;
							top: 0 !important;
						}

					}




				}
			}

			.nav-right {
				visibility: hidden;

				@media (max-width:1100px) {
					display: none;
				}
			}

			ul.short-mob-icon {
				margin: 0px;
				padding: 0px;

				li {
					list-style-type: none;
					display: inline-block;

					a {
						display: inline-block;
						background-color: var(--secondary);
						color: var(--white);
						width: 32px;
						height: 32px;
						line-height: 32px;
						border-radius: 3px;
						text-align: center;
						text-decoration: none;
						font-size: 14px;

						&:hover {
							background-color: var(--secondary);
						}

						&.fav-count {
							position: relative;


							.sub-count {
								display: block;
								position: absolute;
								top: 0px;
								right: 0px;
								width: 16px;
								height: 16px;
								line-height: 16px;
								/*background: #76181e;*/
								background-color: var(--secondary);
								color: var(--white);
								text-align: center;
								font-size: 11px;
								border-radius: 50%;
							}
						}

					}


				}
			}
		}

		.logo {
			a {
				/*width: 165px;*/

				@media (max-width:1024px) {
					/*max-width: 140px;*/
				}
			}


		}

		.nav-right {
			/*display: flex;*/
			display: none;
			justify-content: flex-end;
			/*margin-right: 25px;*/

			.fav-count {
				color: var(--secondary);
				position: relative;
				margin-left: 5px;
				top: 6px;


				.fa {
					font-size: 24px;
				}

				.sub-count {
					display: block;
					position: absolute;
					top: -6px;
					left: 7px;
					width: 18px;
					height: 18px;
					background: red;
					background: var(--primary);
					color: var(--white);
					text-align: center;
					font-size: 12px;
					line-height: 18px;
					border-radius: 50%;
				}
			}
		}

		.topNav {
			margin: 0 auto;

			@media (max-width:1100px) {
				margin: 0;
			}

			@media (max-width:992px) {
				margin: 0;
			}

			.navbar {
				min-height: inherit;
				margin: 0;

				@media (max-width:992px) {
					position: inherit;
				}
			}

			.navbar-toggler {
				background: transparent;
				font-size: 24px;
				color: var(--white);
				padding: 0;
				border: none;
				box-shadow: none;
				display: none;

				@media (max-width:992px) {
					display: inline-block;
					position: absolute;
					left: 0px;
					top: 10px;

				}
			}



			ul {
				margin: 0;
				padding: 0px;

				&.two-col-drop {
					display: flex;
					align-items: center;
					flex-wrap: wrap;
					width: 440px;

					li {
						@media (min-width:993px) {
							width: 50% !important;
						}
					}
				}

				li {
					list-style: none;
					padding: 0px;
					margin: 0px 5px;

					@media (max-width:1240px) {}

					a {
						font-size: 16px;
						color: var(--white);
						display: inline-block;
						padding: 6px 20px;
						background: transparent;
						text-align: left;
						text-transform: uppercase;
						font-weight: bold;
						text-shadow: 0 0 10px rgba(0, 0, 0, .3);
						border-radius: 3px;

						&.dropdown-toggle>.fa-chevron-down {
							margin-left: 5px;
						}

						&.disabled {
							&.pointer {
								pointer-events: none;
							}
						}

						i {
							/*margin-left: 5px;*/
						}

						@media (max-width:1240px) {
							padding: 5px 10px;
							font-size: 15px;
						}

						/*	@media (max-width:1024px) {
							font-size: 12px;
							padding: 5px;

						}*/

						span {
							padding: 3px 5px;
							border-radius: 3px;
							background-color: var(--secondary);
							color: var(--white);
							display: inline-block;
							line-height: 13px;
							font-size: 14px;
							margin-left: 3px;
						}
					}

					&:hover {
						a {
							/*background-color: var(--secondary);*/
							background-color: var(--dark-grey);
							color: var(--white) !important;
						}

						.dropdown-menu {
							display: block;
						}
					}

					.dropdown-menu {
						min-width: 260px;
						left: 0px;
						margin: 0px;
						padding: 0;
						/*background-color: var(--secondary);*/
						background-color: var(--dark-grey);
						border: none;
						border-radius: 0 3px 3px 3px;
						margin-top: -1px;

						@media (max-width:992px) {
							ul {
								padding: 0;
							}
						}

						li {
							float: left;
							width: 100%;
							border-bottom: 1px solid rgba(255, 255, 255, .2);
							margin: 0px;
							padding: 0;



							&:last-child {
								border-bottom: none;
							}
						}

						a {
							font-size: 14px;
							display: block;
							padding: 8px 20px;

							&:hover {
								background-color: var(--primary);
								color: var(--white) !important;
							}
						}
					}
				}

			}
		}
	}

	&.affix {
		/*padding: 14px 0 !important;*/
		border-bottom: 0px solid #f9f9f9;
		/*background-color: rgba(255, 255, 255, 1);*/
		background-color: var(--darker);

		.navbar-area {

			padding: 10px 0;
			transition: all .2s ease-in;

			@media (max-width:992px) {
				/*padding: 0px;*/
			}

			.topNav {
				ul {
					li {
						a {
							text-decoration: inherit;
							text-shadow: none;
							color: var(--white);
						}
					}
				}
			}

			.logo {
				img {
					opacity: 0;
					visibility: hidden;
					transition: all .2s ease-in;

					&.scroll {
						opacity: 1;
						visibility: visible;
						transition: all .2s ease-in;
						background: transparent;
						width: 80px;
						top: -36px;

						@media (max-width:1366px) {}

					}
				}
			}

		}
	}




	@media (max-width:992px) and (min-width: 768px) {
		.navbar-collapse.collapse {
			display: none !important
		}

		.navbar-collapse.collapse.in,
		.navbar-collapse.collapsing {
			display: block !important
		}
	}

	@media screen and (max-width: 992px) {

		.topNav {

			.navbar-collapse {
				position: absolute;
				left: 0px;
				right: 0px;
				top: 58px;
				background: var(--primary);
				padding: 0px;
				overflow: scroll !important;
				max-height: 450px;


				ul {
					magin: 0px;
					float: left;
					width: 100%;
					padding: 10px 15px;

					li {
						float: left;
						width: 100%;

						margin: 0px;

						a {
							color: #ffffff;
						}

						&:hover {
							a {
								color: #ffffff;
							}

							.dropdown-menu {
								display: none;
							}
						}

						&.open {
							.dropdown-menu {
								display: block;
								position: static;
							}
						}

						.dropdown-menu {
							position: static;
							float: none;
							width: auto;
							margin-top: 0;
							background-color: transparent;
							border: 0;
							box-shadow: none;

						}
					}
				}
			}
		}
	}
}

.mm-page {
	&.mm-slideout {
		z-index: inherit;
	}
}

@media (max-width: 992px) {

	.mobile-navigation {
		ul.mm-listview {
			li {

				&.last {
					-webkit-box-pack: center;
					-ms-flex-pack: center;
					justify-content: center;

					a {
						border: 1px solid var(--orangeColor);
						border-radius: 100px;
						text-transform: uppercase;
						padding: 10px 30px;
						max-width: 125px;
						font-size: 13px;
						margin: 22px 0 0 0;

						&:hover {
							background: var(--orangeColor);
							border-color: var(--orangeColor);
						}
					}
				}
			}
		}

	}

	.mm-menu_offcanvas {
		width: 100vw;
		min-width: 100vw;
		z-index: 51;
		max-width: 100vw;
	}

	.mm-wrapper_opening .mm-menu_offcanvas.mm-menu_opened~.mm-slideout {
		-webkit-transform: translate3d(100vw, 0, 0);
		transform: translate3d(100vw, 0, 0);
	}

	.mm-wrapper_opening .mm-menu_offcanvas.mm-menu_opened~.mm-wrapper__blocker.mm-slideout {
		-webkit-transform: translate3d(87vw, 0, 0);
		transform: translate3d(87vw, 0, 0);
		height: 50px;
	}

	.mm-wrapper_opening .mm-menu_offcanvas.mm-menu_opened~.mm-slideout .mm-sronly {
		height: 50px !important;
		width: 50px !important;
		clip-path: inherit !important;
		-webkit-clip-path: inherit !important;
		clip: inherit !important;
		font-size: 0;
	}

	.navbar-nav,
	.navbar-nav>li {
		float: none !Important;
		padding: 0 15px;


		&.search-bl-mob {
			margin-top: 20px;
			display: none !important;
		}

	}

	.mm-wrapper_opening .mm-menu_offcanvas.mm-menu_opened~.mm-slideout .mm-sronly:before {
		content: "\f410";
		font-family: 'Font Awesome 5 Free';
		font-style: normal;
		font-weight: 400;
		font-size: 30px;
		color: #fff;
		padding: 7px;
		float: left;
	}

	.mm-menu_offcanvas {
		display: none;
	}

	.mm-wrapper_opening .mm-menu_offcanvas {
		display: block;
	}

	.mm-wrapper_opening .mm-page.mm-slideout {
		display: none;

	}

	.mm-wrapper_opened .main-footer {
		display: none;
	}

	.mm-navbar {
		background: none;
		border-bottom: 1px solid rgba(255, 255, 255, .5);
		padding-top: 50px;
		margin-bottom: 20px;
		padding-bottom: 10px;
	}

	.mm-navbar__title>span {
		color: var(--white);
		font-size: 20px;
		font-weight: bold;
	}

	.dropdown {
		i {
			display: none;
		}
	}



	.mm-panel {
		background-image: var(--mobileMenuImg);
		background-repeat: no-repeat;
		background-size: cover;
		background-position: center center;
		margin: 0;
		position: relative;

		&:after {
			position: absolute;
			/*z-index: 1;*/
			width: 100%;
			height: 100%;
			left: 0px;
			top: 0px;
			content: "";
			background-color: rgba(196, 191, 182, 0.75);

		}
	}

	.mm-listitem {
		text-align: center;
		font-size: 20px;
		color: var(--white);
		z-index: 2;

		a {
			span {
				padding: 0 8px 2px;
				line-height: 1;
				font-size: 14px;
				border-radius: 3px;
				background-color: var(--primary);
				position: relative;
				top: -2px;
			}
		}


	}

	.mm-listitem:after {
		display: none;
	}

	.mm-listitem__btn {
		border: none !important;
		position: absolute;
		right: 0;
		top: 12px;
		color: var(--white);
	}

	.mm-btn_next {
		position: absolute;
		left: 0;
		width: 100%;
		height: 30px;
		top: 6px;

		&:after {
			right: 30px;
		}
	}


	.mm-btn:after,
	.mm-btn:before {
		border-color: var(--white);
	}

	.mm-listview {
		max-height: 75vh;
		padding-bottom: 35px;
	}


	.mm-wrapper_opening .mm-menu_offcanvas.mm-menu_opened~.mm-wrapper__blocker.mm-slideout {
		-webkit-transform: translate3d(87vw, 0, 0);
		transform: translate3d(87vw, 0, 0);
		height: 50px;
		z-index: 51;
	}

	.mm-wrapper_opening .mm-menu_offcanvas.mm-menu_opened~.mm-slideout .mm-sronly {
		height: 50px !important;
		width: 50px !important;
		clip-path: inherit !important;
		-webkit-clip-path: inherit !important;
		clip: inherit !important;
		font-size: 0;
	}

	.mm-wrapper_opening .mm-menu_offcanvas.mm-menu_opened~.mm-slideout .mm-sronly:before {
		content: "\f410";
		font-family: 'Font Awesome 5 Free';
		font-style: normal;
		font-weight: 400;
		font-size: 30px;
		color: #fff;
		padding: 7px;
		float: left;
	}
}