@import "index.css";

#stick-search,
#stick-search1 {

	&.affix-top,
	&.affix {
		z-index: 100;
		position: static;
	}

	&.affix {
		.sticky-search {
			visibility: visible;
			opacity: 1;
			transition: all .2s ease-in;
		}

	}


	.sticky-search {
		position: fixed;
		display: flex;
		-webkit-box-align: center;
		align-items: center;
		-webkit-box-pack: justify;
		justify-content: space-between;
		background-color: rgba(255, 255, 255, 1);
		padding: 5px 20px;
		top: 0;
		width: 100%;
		z-index: 51;
		visibility: hidden;
		opacity: 0;
		transition: all .2s ease-in;
		border-bottom: 3px solid #f9f9f9;

		&.visible {
			visibility: visible;
			opacity: 1;
		}

		.search-box-home {
			padding: 0;

			.searchbar {
				text-align: left;
				/*display: inline-block;
				background-color: var(--white);
				border-radius: 8px;*/

				.searchbox {
					padding: 0;
					position: relative;
					right: -1px;


					input,
					.search-btn,
					select {
						border-radius: 0;
					}

					.search-btn {
						width: 100%;
					}

					.search-popup-trigger {
						cursor: pointer;
					}

					.form-control:disabled,
					.form-control[readonly] {
						background-color: white;
					}
				}

				.search-form-area {
					display: flex;
					align-items: center;
					justify-content: space-between;

					@media (max-width:1340px) {
						flex-wrap: wrap;
					}


					.form-flex {
						padding: 10px;
						position: relative;


						@media (max-width:1140px) {
							width: 100%;
							padding: 10px 0;
						}

						&:first-child {
							border-left: none;
						}

						.form-bx-area {
							position: relative;
							padding-right: 125px;
							padding-left: 0px;
							border-bottom: 2px solid var(--grey);

							&.pinarea {
								@media (min-width:1240px) {
									padding-right: 55px;
									min-width: 330px;
								}
							}

							@media (max-width:1366px) {
								padding-right: 55px;
							}

							button.form-control {
								min-width: 210px;

								@media (max-width:1340px) {
									font-size: 18px;
								}
							}


						}

						.fa,
						.far,
						.fas {
							position: absolute;
							right: 2px;
							top: auto;
							color: var(--black);
							z-index: 2;
							font-size: 20px;
							pointer-events: none;
							bottom: 5px;



							&.shadow {
								font-size: 40px;
								top: 0;
								color: var(--grey);
								z-index: 1;
								right: 10px;

							}

							&.fa-map-marker-alt {
								right: 8px;

								@media (max-width:1140px) {
									right: 0;
								}

								&.shadow {
									@media (max-width:1140px) {
										right: 9px !important;
									}
								}
							}
						}


						.form-control {
							background-color: transparent;
							border: none;
							border-radius: 0;
							padding: 10px 15px;
							height: inherit;
							box-shadow: inherit;
							font-family: var(--dfont2);
							font-size: 17px;
							text-align: left;


						}


					}

					.form-btn {

						@media (max-width:1140px) {
							width: 100%;
							text-align: center;
						}

						.btn {
							align-items: center;
							display: flex;
							justify-content: center;
							font-size: 26px;
							/*border-radius: 0 8px 8px 0;*/
							margin-left: 30px;
							font-family: var(--dfont2);
							font-weight: bold;
							/*height: 120px;*/
							position: relative;


							@media (max-width:1340px) {
								/*font-size: 24px;
								height: 100px;*/

							}

							@media (max-width:1140px) {
								font-size: 16px;
								border-radius: 4px;
								position: relative;
								height: inherit;
							}

							i {
								margin: 0 8px;
								color: rgba(255, 255, 255, .25);
								/*font-size: 30px;*/

							}


						}
					}
				}

			}
		}

		.sr-menu i {
			color: var(--text-dark);
			font-size: 24px;
			padding: 0 10px;
			position: absolute;
			z-index: 2;
			right: 10px;
			top: 40%;
		}

		@media (max-width:1024px) {
			display: none !important;
		}

		.dropdown {



			&.open {
				.icon-bar {
					&.middle-bar {
						display: none;
					}

					&.bottom-bar {
						position: relative;
						transform: rotate(45deg);
						top: -4px;
					}

					&.top-bar {
						position: relative;
						transform: rotate(-45deg);
						top: -2px;
					}
				}
			}

			.dropdown-menu {
				border-radius: 0;
				background-color: var(--primary);
				top: 50px;
				width: 450px;
				border: none;
				padding: 15px;
				right: -450px;
				transition: none !important;

				&.dropdown-menu-right {
					right: -20px;
				}

				ul.short-menu {
					margin: 0px;
					padding: 0px;

					li {
						list-style-type: none;
						display: inline-block;
						width: 50%;
						float: left;
						padding: 10px 5px;

						&.submenu {
							width: 100%;

							>a {
								&.pointer {
									pointer-events: none;
								}

								&.active {
									color: var(--secondary);
								}
							}

							ul.menu {
								margin: 0px;
								padding: 0 0 0 10px;

								li {
									display: block;
									float: left;
									width: 50%;
								}
							}
						}

						a {
							font-size: 14px;
							color: var(--white);
							display: inline-block;
							text-align: left;
							text-transform: uppercase;
							font-weight: bold;

							&:hover {
								color: var(--secondary);
							}
						}
					}
				}

			}
		}

		.icon-menu {
			background-color: transparent;
			border: none;
		}

		.icon-bar {
			background-color: var(--primary);

			&.icon-bar {
				display: block;
				width: 30px;
				height: 2px;
				border-radius: 1px;
				margin: 0px 0;

				&.middle-bar {
					margin: 5px 0;
				}
			}
		}

		.search-short-area {
			padding: 0;
			margin: 0;

			ul {
				margin: 0px;
				padding: 0px;

				li {
					list-style-type: none;
					display: inline-block;
					margin-left: 10px;
					vertical-align: middle;

					a {
						text-decoration: none;
						color: var(--primary);
						font-size: 15px;

						i {
							font-size: 24px;
							margin-right: 5px;
							vertical-align: middle;
						}
					}

					.fav-count {
						color: var(--primary);
						position: relative;


						.sub-count {
							display: block;
							position: absolute;
							top: -8px;
							left: 10px;
							width: 18px;
							height: 18px;
							background: var(--secondary);
							/*background: var(--black);*/
							color: var(--white);
							text-align: center;
							font-size: 12px;
							line-height: 18px;
							border-radius: 50%;
						}

						span {
							@media (max-width:992px) {
								display: none;
							}
						}
					}
				}

				@media (max-width:992px) {
					text-align: center;

				}
			}


		}



	}




}